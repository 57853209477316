import React from "react";
import { Link } from "gatsby";

export default function HomepageLink({ to, children, image }) {
    return (
        <Link className="homepage-link" to={to}>
            <div 
                style={{
                    backgroundImage: `url(${
                        !!image.childImageSharp
                            ? image.childImageSharp.fluid.src
                            : image
                    })`,
                    backgroundPosition: `center`,
                    backgroundSize: `cover`,
                    
                    
                }}
            >
                <h2 style={{color: "#ffffff", fontSize: 30}}>{children}</h2>
            </div>
        </Link>
    );
}
